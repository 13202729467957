import Navbar from "./navbar/Navbar"
import "./header.css"
import logo from "../images/logo.jpg"
import { Link, Outlet } from "react-router-dom"
import { useRef, useEffect } from "react"

export default function Header() {
  const headerRef = useRef(null)
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY
      if (scrollY > 50) {
        headerRef.current.style.backgroundColor = "white"
        headerRef.current.classList.add("scrolled")
      } else {
        headerRef.current.style.backgroundColor = "initial"
        headerRef.current.classList.remove("scrolled")
      }
    }

    window.addEventListener("scroll", handleScroll)

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])
  return (
    <>
      <header ref={headerRef}>
        <div className="header-wrapper">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
          <Navbar />
        </div>
      </header>
      <Outlet />
    </>
  )
}
