import "./navbar.css"
import { NavLink } from "react-router-dom"
import { useState } from "react"

export default function Navbar() {
  const [burgerMenu, setBurgerMenu] = useState("burger-active burger-hidden")
  const [menuOpen, setMenuOpen] = useState("nav-list menu-hidden")
  const [isMenuClicked, setIsMenuClicked] = useState(false)

  const updateMenu = () => {
    setIsMenuClicked(!isMenuClicked)
    if (!isMenuClicked) {
      setBurgerMenu("burger-active burger-closed")
      setMenuOpen("nav-list menu-hidden")
    } else {
      setBurgerMenu("burger-active burger-clicked")
      setMenuOpen("nav-list")
    }
  }

  return (
    <>
      <nav>
        <div className="burger-menu">
          <div className={burgerMenu} onClick={updateMenu}></div>
          <div className={burgerMenu} onClick={updateMenu}></div>
          <div className={burgerMenu} onClick={updateMenu}></div>
        </div>
        <ul className={menuOpen}>
          <li>
            <NavLink to="/" onClick={updateMenu}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink exact to="services" onClick={updateMenu}>
              Services
            </NavLink>
          </li>
          <li>
            <NavLink exact to="gallery" onClick={updateMenu}>
              Gallery
            </NavLink>
          </li>
          <li>
            <NavLink exact to="reviews" onClick={updateMenu}>
              Reviews
            </NavLink>
          </li>
          <div className="header-btn">
            <a className="btn" href="tel:6892326676">
              Contact Us <span className="fa-solid fa-arrow-right"></span>
            </a>
          </div>
        </ul>
      </nav>
    </>
  )
}
