import "./reviews.css"
import { Helmet } from "react-helmet"

export default function Reviews() {
  return (
    <>
      <Helmet>
        <title>
          Read Our Customer Reviews - Strut Your Mutts Treadmill Dog Walking
          Services, Lakeland, FL
        </title>
        <meta
          name="description"
          content="Read our customer reviews and see why Strut Your Mutts Treadmill Dog Walking Services is the best choice for your furry friend in Lakeland, FL."
        />
      </Helmet>
      <div className="reviews">
        <div className="reviews-inner">
          <h1>Coming Soon!</h1>
        </div>
      </div>
    </>
  )
}
