import "./services.css"
import { Helmet } from "react-helmet"

export default function Services() {
  return (
    <>
      <Helmet>
        <title>
          Strut Your Mutts - Professional Treadmill Dog Walking in Lakeland, FL
        </title>
        <meta
          name="description"
          content="Get professional and safe treadmill dog walking services in Lakeland, FL with Strut Your Mutts. Let us give your furry friend the exercise they deserve!"
        />
      </Helmet>
      <div className="services">
        <div className="services-inner">
          <h1>Pricing</h1>
          <div className="services-content">
            <div className="service-box">
              <h2>Almost Overnight Care</h2>
              <span className="price">
                Starting at <strong>$100</strong>
              </span>
              <p>2 hours in the evening + 1 hour the following morning.</p>
            </div>
            <div className="service-box">
              <h2>Overnight Stay</h2>
              <span className="price">
                Starting at <strong>$150</strong>
              </span>
              <p>Per night.</p>
            </div>
            <div className="service-box">
              <h2>Live-in Care</h2>
              <span className="price">
                Starting at <strong>$250</strong>
              </span>
              <p>Per day.</p>
            </div>
            <div className="service-box">
              <h2>Local Pet Taxi</h2>
              <span className="price">
                Starting at <strong>$28</strong>
              </span>
              <p>Up to 5 miles + $1 per additional mile.</p>
            </div>
            <div className="service-box">
              <span className="price">
                <strong>Custom Rates</strong>
              </span>
              <p>Long distance pet transportation and traveling pet care.</p>
            </div>
          </div>
          <p>
            As always, our prices include two pets and there is an additional $5
            fee per visit for homes with more than two pets. And we are proud to
            offer a 10% discount on all services to active and retired military.
          </p>
        </div>
      </div>
    </>
  )
}
