import { Helmet } from "react-helmet"
import whiteDog from "../images/dog-sec1.jpg"
import dogIcon1 from "../images/dog-icon1.png"
import dogIcon2 from "../images/dog-icon2.png"
import dogIcon3 from "../images/dog-icon3.png"
import dogSec4 from "../images/whitedog.png"
import "./home.css"

export default function Home() {
  return (
    <>
      <Helmet>
        <title>
          Strut Your Mutts - Treadmill Dog Walking Services in Lakeland, FL
        </title>
        <meta
          name="description"
          content="Trust Strut Your Mutts for the best treadmill dog walking services in Lakeland, FL. We provide safe and professional care for your furry friend."
        />
      </Helmet>
      <main className="main">
        <div className="main-inner">
          <div className="main-inner_img">
            <img src={whiteDog} alt="white dog" />
          </div>
          <div className="main-inner_content">
            <h1>Customized Treadmill Walking Services for Your Furry Friend</h1>
            <p>
              At Strut Your Mutts, we understand how important it is to keep
              your furry friend happy and healthy. That's why we offer
              personalized treadmill walking sessions that cater to your dog's
              specific needs and fitness goals.
            </p>
            <p>
              Our team of experienced and trained professionals will work with
              you to create a tailored treadmill walking plan for your dog.
              Whether you have a young puppy who needs to burn off energy or an
              older dog who needs low-impact exercise, we've got you covered.
            </p>
            <p>
              But we offer more than just treadmill walks. Our team is dedicated
              to providing a fun and interactive experience for your dog. We'll
              play games, give treats, and provide plenty of love and affection
              to keep your dog engaged and motivated.
            </p>
            <p>
              At Strut Your Mutts, we believe that every dog deserves the
              opportunity to live a healthy and active lifestyle. That's why we
              offer flexible scheduling and convenient pickup and drop-off
              services to make it easy for you to give your dog the exercise
              they need.
            </p>
            <button className="btn">
              Find Out More <span className="fa-solid fa-arrow-right"></span>
            </button>
          </div>
        </div>
      </main>
      <div className="hot-asphalt">
        <h2>HOT ASPHALT</h2>
        <div className="temps-container">
          <div className="temp-titles"></div>
          <div className="temps">
            <div className="temp-nums">
              <span className="temp-title">Air Temperature</span>
              <p>77°</p>
              <p>86°</p>
              <p>87°</p>
            </div>
            <div className="temp-nums">
              <p>............</p>
              <p>............</p>
              <p>............</p>
            </div>
            <div className="temp-nums">
              <span className="temp-title">Asphalt Temperature</span>
              <p>125°</p>
              <p>135°</p>
              <p>145°</p>
            </div>
          </div>
          <div className="hot-asphalt-info">
            <p>
              At 125° skin destruction can occur in just 60 seconds. Always
              check the asphalt prior to allowing your pet to walk on it.
            </p>
            <p>
              <strong>PAWS WILL GET BURNED!</strong>
            </p>
            <p>
              TIP: If it's too hot for YOUR bare feet, it's too hot for THEIRS!
            </p>
          </div>
        </div>
      </div>
      <div className="home-sec2">
        <div className="home-sec2_cards">
          <div className="home-sec2_card">
            <h2 className="card-title">Interactive and Fun Sessions</h2>
            <img src={dogIcon1} alt="dog playing" />
            <p>
              At Strut Your Mutts, we believe that exercise should be enjoyable
              for your furry friend. That's why we offer interactive and fun
              treadmill walking sessions that include games, treats, and plenty
              of love and affection.
            </p>
          </div>
          <div className="home-sec2_card">
            <h2 className="card-title">Customized Treadmill Walking Plan</h2>
            <img src={dogIcon2} alt="dog playing" />
            <p>
              Our team of experienced professionals will work with you to create
              a personalized treadmill walking plan for your dog. We'll take
              into account your dog's age, breed, fitness level, and any medical
              conditions to ensure they get the most out of their treadmill
              walks.
            </p>
          </div>
          <div className="home-sec2_card">
            <h2 className="card-title">
              Flexible Scheduling and Convenient Pickup/Drop-off
            </h2>
            <img src={dogIcon3} alt="dog playing" />
            <p>
              We understand that life can be busy, which is why we offer
              flexible scheduling options and convenient pickup and drop-off
              services. Let us take the stress out of making sure your dog gets
              the exercise they need.
            </p>
          </div>
        </div>
      </div>
      <div className="home-sec3">
        <div className="home-sec3_inner">
          <h3>
            Expert Treadmill Walking Services for Dogs of All Shapes and Sizes
          </h3>
          <p>
            At Strut Your Mutts, we pride ourselves on offering high-quality
            treadmill walking services for dogs of all shapes, sizes, and
            breeds. Whether you have a small toy breed or a large working breed,
            we have the experience and knowledge to provide a safe and effective
            treadmill walking program for your furry friend.
          </p>
          <p>
            Our team of trained professionals is dedicated to ensuring that each
            and every treadmill walking session is enjoyable and beneficial for
            your dog. We'll start by conducting a thorough assessment of your
            dog's fitness level and any medical conditions that may impact their
            treadmill walking plan. From there, we'll work with you to determine
            the frequency, duration, and intensity of your dog's treadmill
            walks.
          </p>
        </div>
      </div>
      <div className="home-sec4">
        <div className="home-sec4_inner">
          <div className="home-sec4_inner-content">
            <h3>The Benefits of Treadmill Walking for Dogs</h3>
            <p>
              Treadmill walking is a fantastic way to help your dog stay fit and
              healthy. It provides a low-impact form of exercise that is easy on
              joints and muscles, making it a great option for dogs of all ages
              and fitness levels. In addition to improving your dog's physical
              health, treadmill walking can also provide numerous mental and
              emotional benefits. It can help reduce stress, anxiety, and
              boredom, and keep your dog's brain active and engaged.
            </p>
            <p>
              Treadmill walking can also be a convenient and safe way to give
              your dog the exercise they need, especially if you live in an area
              with inclement weather or limited access to safe outdoor spaces.
              It's also a great option for dogs who may be prone to getting lost
              or injured when off-leash, as they can be securely attached to the
              treadmill while walking. Overall, treadmill walking can be an
              excellent addition to your dog's fitness routine, helping them
              stay healthy and happy for years to come.
            </p>
            <button className="btn">
              Find Out More <span className="fa-solid fa-arrow-right"></span>
            </button>
          </div>
          <div className="home-sec4_inner-img">
            <img src={dogSec4} alt="white dog with toy" />
          </div>
        </div>
      </div>
    </>
  )
}
