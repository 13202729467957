import dogImg from "../images/dog-sec1.jpg"
import "./gallery.css"
import { Helmet } from "react-helmet"

export default function Gallery() {
  return (
    <>
      <Helmet>
        <title>
          See Our Happy Clients - Strut Your Mutts Treadmill Dog Walking
          Services, Lakeland, FL
        </title>
        <meta
          name="description"
          content="Check out our photo gallery and see the happy and satisfied clients of Strut Your Mutts Treadmill Dog Walking Services in Lakeland, FL."
        />
      </Helmet>
      <div className="gallery">
        <div className="gallery-inner">
          <img src={dogImg} alt="dog sitting" />
          <img src={dogImg} alt="dog sitting" />
          <img src={dogImg} alt="dog sitting" />
          <img src={dogImg} alt="dog sitting" />
          <img src={dogImg} alt="dog sitting" />
          <img src={dogImg} alt="dog sitting" />
          <img src={dogImg} alt="dog sitting" />
          <img src={dogImg} alt="dog sitting" />
          <img src={dogImg} alt="dog sitting" />
          <img src={dogImg} alt="dog sitting" />
          <img src={dogImg} alt="dog sitting" />
          <img src={dogImg} alt="dog sitting" />
          <img src={dogImg} alt="dog sitting" />
          <img src={dogImg} alt="dog sitting" />
          <img src={dogImg} alt="dog sitting" />
        </div>
      </div>
    </>
  )
}
