import Header from "./header/Header"
import Hero from "./hero/Hero"
import Home from "./home/Home"
import Services from "./services/Services"
import Gallery from "./gallery/Gallery"
import Reviews from "./reviews/Reviews"
import aboutBg from "./images/floofy.png"
import galleryBg from "./images/banner-gallery.png"
import heroBg from "./images/hero-img.png"
import Footer from "./footer/Footer"
import { useRef, useEffect } from "react"
import "./App.css"
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom"

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Header />}>
      <Route
        index
        element={
          <>
            <Hero
              imageURL={heroBg}
              title="Your Best Friend’s New Best Friend."
              subtitle="Get your dog moving and burn off excess energy with our custom
            treadmill walking service – a safe and convenient way to ensure your
            beloved pet stays fit and healthy every day!"
            />
            <Home />
          </>
        }
      />
      <Route
        path="services"
        element={
          <>
            <Hero
              imageURL={aboutBg}
              title="Your Dog's Fitness Journey Starts Here!"
              subtitle="Our team is dedicated to providing the best possible care for your dog, and we can't wait to work with you. Contact us today to learn more about our services and how we can help your dog reach their full potential."
            />
            <Services />
          </>
        }
      />
      <Route
        path="gallery"
        element={
          <>
            <Hero
              imageURL={galleryBg}
              title="Experience the fun of treadmill walking with your dog"
              subtitle="Get a behind-the-scenes look at how we help your furry friend stay fit and healthy with our personalized treadmill walking services"
            />
            <Gallery />
          </>
        }
      />
      <Route
        path="reviews"
        element={
          <>
            <Hero
              imageURL={galleryBg}
              title="Experience the fun of treadmill walking with your dog"
              subtitle="Get a behind-the-scenes look at how we help your furry friend stay fit and healthy with our personalized treadmill walking services"
            />
            <Reviews />
          </>
        }
      />
    </Route>
  )
)

function App() {
  return (
    <>
      <RouterProvider router={router} />
      <Footer />
    </>
  )
}

export default App
