import logo from "../images/logo.jpg"
import footerDog from "../images/footer-dog.svg"
import "./footer.css"

export default function Footer() {
  return (
    <>
      <footer>
        <div className="footer-inner">
          <div className="footer-inner_left">
            <img src={logo} alt="logo" />
            <p>
              <strong>Your Best Friend’s New Best Friend.</strong>
            </p>
            <a href="tel:6892326679">(689) 232-6679</a>
            <p>
              1234 Main St. <br /> Lakeland, FL 12345
            </p>
            <div className="footer-inner_left-sm-links">
              <span className="fa-brands fa-facebook"></span>
              <span className="fa-brands fa-instagram"></span>
              <span className="fa-brands fa-twitter"></span>
            </div>
          </div>
          <div className="footer-inner_mid">
            <h3>Quick Links</h3>
            <ul>
              <li>home</li>
              <li>about</li>
              <li>services</li>
              <li>photos</li>
              <li>contact</li>
            </ul>
          </div>
          <div className="footer-right">
            <img src={footerDog} alt="dog walk" />
          </div>
        </div>
      </footer>
      <div className="copyright">
        <p>©ACroweDesign 2022</p>
      </div>
    </>
  )
}
