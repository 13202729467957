import heroImg from "../images/hero-img.png"
import aboutBg from "../images/banner-about.png"
import "./hero.css"

export default function Hero({ imageURL, title, subtitle }) {
  return (
    <div className="hero">
      <img src={imageURL} alt="banner img" />
      <div className="hero-container">
        <div className="hero_content">
          <span className="hero_content-title">{title}</span>
          <span className="hero_content-subtitle">{subtitle}</span>
          <button className="btn">
            Learn More <span className="fa-solid fa-arrow-right"></span>
          </button>
        </div>
        <div className="hero_img"></div>
      </div>
    </div>
  )
}
